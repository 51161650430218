import React, { useState, useEffect, useCallback } from "react";

interface PhotoGalleryProps {
  images: string[];
  duration?: number;
}

const PhotoGallery: React.FC<PhotoGalleryProps> = ({
  images,
  duration = 4000,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [progress, setProgress] = useState(0);
  const [isPaused, setIsPaused] = useState(false);

  // Reset timer effect
  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (!isPaused) {
      setProgress(0);
      timer = setInterval(() => {
        setProgress((prev) => {
          const newProgress = prev + (100 / duration) * 16;
          if (newProgress >= 100) {
            if (currentIndex < images.length - 1) {
              setCurrentIndex((prev) => prev + 1);
            } else {
              setProgress(0);
              setCurrentIndex(0);
            }
            return 0;
          }
          return newProgress;
        });
      }, 16);
    }
    return () => clearInterval(timer);
  }, [currentIndex, duration, isPaused, images.length]);

  const handlePrevious = useCallback(() => {
    if (currentIndex > 0) {
      setCurrentIndex((prev) => prev - 1);
      setProgress(0);
      setIsPaused(false);
    }
  }, [currentIndex]);

  const handleNext = useCallback(() => {
    if (currentIndex < images.length - 1) {
      setCurrentIndex((prev) => prev + 1);
      setProgress(0);
      setIsPaused(false);
    }
  }, [currentIndex, images.length]);

  const handleAreaClick = (e: React.MouseEvent) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const isLeftSide = x < rect.width / 2;

    if (isLeftSide) {
      handlePrevious();
    } else {
      handleNext();
    }
  };

  return (
    <div
      className="position-relative"
      style={{ width: "100%", height: "100vh", maxHeight: "100%" }}
    >
      {/* Progress bars */}
      <div
        className="position-absolute w-100 d-flex gap-1 p-2"
        style={{ zIndex: 2 }}
      >
        {images.map((_, idx) => (
          <div
            key={idx}
            className="flex-grow-1 bg-white bg-opacity-50 rounded"
            style={{ height: "2px" }}
          >
            <div
              className="h-100 bg-white"
              style={{
                width:
                  idx === currentIndex
                    ? `${progress}%`
                    : idx < currentIndex
                    ? "100%"
                    : "0%",
                transition: "width 0.1s linear",
              }}
            />
          </div>
        ))}
      </div>

      {/* Image container */}
      <div
        className="position-relative w-100 h-100"
        onClick={handleAreaClick}
        onMouseEnter={() => setIsPaused(true)}
        onMouseLeave={() => setIsPaused(false)}
        style={{ height: "100vh" }}
      >
        <img
          src={images[currentIndex]}
          alt={`Gallery image ${currentIndex + 1}`}
          className="w-100 h-100"
          style={{
            objectFit: "cover",
            width: "100%",
            height: "calc(100% - 40px)",
            position: "absolute",
            top: 0,
            left: 0,
          }}
        />

        {/* Navigation areas */}
        <div
          className="position-absolute top-0 start-0 h-100"
          style={{
            width: "50%",
            cursor: currentIndex > 0 ? "pointer" : "default",
          }}
          onClick={(e) => {
            e.stopPropagation();
            handlePrevious();
          }}
        />
        <div
          className="position-absolute top-0 end-0 h-100"
          style={{
            width: "50%",
            cursor: currentIndex < images.length - 1 ? "pointer" : "default",
          }}
          onClick={(e) => {
            e.stopPropagation();
            handleNext();
          }}
        />
      </div>
    </div>
  );
};

export default PhotoGallery;
