import { useTranslation } from "react-i18next";
import { SearchParameters } from "../../../assets/Services/metasearch";
import { useMediaQuery } from "react-responsive";
import { SMALL_SCREEN } from "../../../assets/Utils/generic";
import {
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
  Dispatch,
} from "react";
import IconNew from "../../../assets/TakyonDesignSystem/components/Icon/Icon";
import Slider from "../../../assets/TakyonDesignSystem/components/Slider/Slider";
import Button from "../../../assets/TakyonDesignSystem/components/Button/Button";
import { fireTagManagerEvent } from "../../../assets/Services/tagmanager";
import {
  facility_generals,
  facility_comforts,
  facility_transports,
  property_types,
  filter_class,
  filter_accessible_facilities,
  filter_meals,
  filter_booking_rules,
} from "./FilterTags";
import SelectableButton from "../../../assets/TakyonDesignSystem/components/Button/SelectableButton";
import { MAX_PRICE, MIN_PRICE } from "./Search";
import { Accordion } from "react-bootstrap";
import { set } from "lodash";

/**
 * Replaces all the filters that match the query with the provided value
 * @return the modified parameters object
 */
export function updateCategoryFilter(
  query: string,
  value: string,
  currentFilters: SearchParameters,
  exactMatch: boolean = false,
): SearchParameters {
  const cat = currentFilters.categories_filter ?? "";

  const match = (tag: string, query: string) =>
    exactMatch ? tag === query : tag.includes(query);

  const catArray = cat
    .split(",")
    // Remove the tag if the value is empty
    .filter((tag) => !(match(tag, query) && value === ""))
    .map((tag) => {
      if (!match(tag, query)) return tag; // Keep the tag if it doesn't match the query
      return value; // Replace the tag if it matches the query
    });

  // Add the tag if it doesn't exist and the value is not empty
  if (value !== "" && !catArray.some((tag) => match(tag, query))) {
    catArray.push(value);
  }

  return {
    ...currentFilters,
    categories_filter: catArray.filter((e) => e).join(","),
  };
}

function CategoryFilter({
  title,
  filters,
  setFilters,
  filterList,
  filterKey,
}: {
  title?: string;
  filters: SearchParameters;
  setFilters: Dispatch<SetStateAction<SearchParameters>>;
  filterList: Record<string, string>;
  filterKey: string;
}) {
  const { t } = useTranslation();
  const filterValues = useMemo(
    () =>
      filters.categories_filter?.match(new RegExp(`${filterKey}::(\\d+)`, "g")),
    [filters, filterKey],
  );

  const onClick = (num: string) => {
    const filter = `${filterKey}::${num}`;
    const maxElements = Object.keys(filterList).length;

    setFilters((prevFilters: SearchParameters) => {
      if (filterValues?.length === maxElements) {
        // deselect all values
        const deselected = updateCategoryFilter(
          `${filterKey}::`,
          "",
          prevFilters,
          false,
        );

        // select only the correct one
        return updateCategoryFilter(filter, filter, deselected, false);
      } else if (filterValues?.includes(filter)) {
        if (filterValues?.length === 1) {
          // select all values
          return Object.keys(filterList).reduce(
            (acc, n) =>
              updateCategoryFilter(
                `${filterKey}::${n}`,
                `${filterKey}::${n}`,
                acc,
              ),
            prevFilters,
          );
          // deselect the filter
        } else return updateCategoryFilter(filter, "", prevFilters, false);
      }
      return updateCategoryFilter(filter, filter, prevFilters, false);
    });
  };

  return (
    <>
      {title && (
        <>
          <p className="fs-body-lg medium">{t(title)}</p>
          <div className="height-16"></div>
        </>
      )}
      <div className="d-flex gap-2 flex-wrap" style={{ paddingBottom: "23px" }}>
        {Object.entries(filterList).map(([num, type]) => (
          <SelectableButton
            selected={
              !!filterValues?.includes(`${filterKey}::${num}`) &&
              (filterValues?.length ?? 0) < Object.keys(filterList).length
            }
            onClick={() => onClick(num)}
          >
            {t(`${filterKey}.${type}`)}
          </SelectableButton>
        ))}
      </div>
    </>
  );
}

function PillsFilter({
  title,
  filters,
  setFilters,
  filterList,
  filterKey,
}: {
  title?: string;
  filters: SearchParameters;
  setFilters: Dispatch<SetStateAction<SearchParameters>>;
  filterList: Record<string, string>;
  filterKey: string;
}) {
  const { t } = useTranslation();
  const filterValues = useMemo(
    () =>
      filters.categories_filter?.match(new RegExp(`${filterKey}::(\\d+)`, "g")),
    [filters, filterKey],
  );

  return (
    <>
      {title && (
        <>
          <p className="fs-body-lg medium">{t(title)}</p>
          <div className="height-16"></div>
        </>
      )}
      <div className="d-flex gap-2 flex-wrap" style={{ paddingBottom: "23px" }}>
        {Object.entries(filterList).map(([num, type]) => (
          <SelectableButton
            selected={!!filterValues?.includes(`${filterKey}::${num}`)}
            onClick={() => {
              const filter = `${filterKey}::${num}`;
              if (!filterValues?.includes(filter))
                setFilters(updateCategoryFilter(filter, filter, filters, true));
              else setFilters(updateCategoryFilter(filter, "", filters, true));
            }}
          >
            {t(`${filterKey}.${type}`)}
          </SelectableButton>
        ))}
      </div>
    </>
  );
}

function CheckboxFilter({
  title,
  filters,
  setFilters,
  filterList,
  filterKey,
}: {
  title?: string;
  filters: SearchParameters;
  setFilters: Dispatch<SetStateAction<SearchParameters>>;
  filterList: Record<string, string>;
  filterKey: string;
}) {
  const { t } = useTranslation();
  const filterValues = useMemo(
    () =>
      filters.categories_filter?.match(new RegExp(`${filterKey}::(\\d+)`, "g")),
    [filters, filterKey],
  );

  return (
    <>
      {title && (
        <>
          <p className="fs-body-lg medium">{t(title)}</p>
          <div className="height-16"></div>
        </>
      )}
      <div
        className="d-flex flex-column gap-2"
        style={{ paddingBottom: "23px" }}
      >
        {Object.entries(filterList).map(([num, type]) => {
          const filter = `${filterKey}::${num}`;
          const isChecked = filterValues?.includes(filter);

          return (
            <div
              key={num}
              className="d-flex gap-2 align-items-center fs-body-sm"
            >
              <div>
                <input
                  type="checkbox"
                  id={`${filterKey}-${num}`}
                  checked={!!isChecked}
                  onChange={() => {
                    if (!isChecked) {
                      setFilters(
                        updateCategoryFilter(filter, filter, filters, true),
                      );
                    } else {
                      setFilters(
                        updateCategoryFilter(filter, "", filters, true),
                      );
                    }
                  }}
                />
              </div>
              <label htmlFor={`${filterKey}-${num}`}>
                {t(`${filterKey}.${type}`)}
              </label>
            </div>
          );
        })}
      </div>
    </>
  );
}

function FiltersAccordion({
  filters,
  setFilters,
}: {
  filters: SearchParameters;
  setFilters: Dispatch<SetStateAction<SearchParameters>>;
}) {
  const { t } = useTranslation();
  const keys = [
    "property_type",
    "class",
    "mealplan",
    "fc",
    "accessible_facilities",
  ];

  const defaultActiveKeys = useMemo(
    () =>
      keys.reduce((acc, key, i) => {
        const match = filters.categories_filter?.match(
          new RegExp(`${key}::(\\d+)`, "g"),
        );

        const isAllPropertyTypes =
          match?.length === Object.keys(property_types).length;

        if (match && !isAllPropertyTypes) acc.push(i.toString());
        return acc;
      }, [] as string[]),
    [filters],
  );

  return (
    <Accordion
      className="border-top"
      style={{ marginTop: "26px" }}
      defaultActiveKey={defaultActiveKeys}
      alwaysOpen
    >
      <Accordion.Item eventKey="0">
        <Accordion.Header>{t("ds.property_type")}</Accordion.Header>
        <Accordion.Body className="p-0">
          <CategoryFilter
            filterList={property_types}
            filters={filters}
            setFilters={setFilters}
            filterKey="property_type"
          />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1" className="border-top">
        <Accordion.Header>{t("ds.filter_class")}</Accordion.Header>
        <Accordion.Body className="p-0">
          <PillsFilter
            filterList={filter_class}
            filters={filters}
            setFilters={setFilters}
            filterKey="class"
          />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2" className="border-top">
        <Accordion.Header>{t("ds.filter_mealplan")}</Accordion.Header>
        <Accordion.Body className="p-0">
          <CheckboxFilter
            filterList={filter_meals}
            filters={filters}
            setFilters={setFilters}
            filterKey="mealplan"
          />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3" className="border-top">
        <Accordion.Header>{t("ds.filter_fc")}</Accordion.Header>
        <Accordion.Body className="p-0">
          <CheckboxFilter
            filterList={filter_booking_rules}
            filters={filters}
            setFilters={setFilters}
            filterKey="fc"
          />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4" className="border-top">
        <Accordion.Header>{t("ds.filter_visual_ads")}</Accordion.Header>
        <Accordion.Body className="p-0">
          <PillsFilter
            filterList={filter_accessible_facilities}
            filters={filters}
            setFilters={setFilters}
            filterKey="accessible_facilities"
          />
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default function FiltersModal({
  onFilter,
  searchParameters,
}: {
  onFilter(filter: SearchParameters): void;
  searchParameters: SearchParameters;
}) {
  const { t } = useTranslation();
  const isSmallScreen = useMediaQuery({ query: SMALL_SCREEN });

  const [filters, setFilters] = useState<SearchParameters>(searchParameters);

  const [sliderMin, setSliderMin] = useState<number>(
    Number(searchParameters.price_min) || MIN_PRICE,
  );
  const [sliderMax, setSliderMax] = useState<number>(
    Number(searchParameters.price_max) || MAX_PRICE,
  );

  useEffect(() => {
    setFilters((f) => ({
      ...f,
      price_min: sliderMin.toFixed(0),
    }));
  }, [sliderMin]);

  useEffect(() => {
    setFilters((f) => ({
      ...f,
      price_max: sliderMax.toFixed(0),
    }));
  }, [sliderMax]);

  const priceFilterValidation = useCallback(() => {
    if (sliderMin < MIN_PRICE) setSliderMin(MIN_PRICE);
    if (sliderMax > MAX_PRICE) setSliderMax(MAX_PRICE);
    if (sliderMax < sliderMin) {
      setSliderMin(MIN_PRICE);
      setSliderMax(MAX_PRICE);
    }
  }, [sliderMin, sliderMax]);

  const defaultFilters: SearchParameters = {
    price_min: "10",
    price_max: "1000",
    certified_only: undefined,
    includes_benefits: undefined,
    categories_filter: "",
  };

  // Conversione da posizione slider a prezzo reale
  function sliderToPrice(sliderValue: number) {
    const logMin = Math.log10(MIN_PRICE);
    const logMax = Math.log10(MAX_PRICE);
    const logValue = (sliderValue / 100) * (logMax - logMin) + logMin;
    const price = Math.pow(10, logValue);
    return price;
  }

  // Conversione da prezzo reale a posizione slider
  function priceToSlider(price: number) {
    const logMin = Math.log10(MIN_PRICE);
    const logMax = Math.log10(MAX_PRICE);
    const logPrice = Math.log10(price);
    return ((logPrice - logMin) / (logMax - logMin)) * 100;
  }

  // Funzione per arrotondare a intervalli sensati
  const roundToNearestInterval = (value: number) => {
    if (value <= 200) return Math.round(value / 5) * 5;
    if (value <= 500) return Math.round(value / 10) * 10;
    return Math.round(value / 50) * 50;
  };

  return (
    <>
      <div className="d-flex justify-content-center p-2 border-bottom">
        <p className="fs-body-sm medium m-0">{t("ds.filters")}</p>
      </div>
      <div style={{ padding: "24px 32px" }} className="w100 modal-body">
        <p className="fs-body-lg medium">{t("ds.most_used_filters")}</p>
        <div className="height-16"></div>
        <div style={{ gap: "12px" }} className="d-flex justify-content-around">
          <div
            className={`chip toggle py-4 ${
              filters.certified_only ? "selected" : ""
            }`}
            style={{
              borderRadius: "20px",
              width: "33.33%",
              flexDirection: "column",
              justifyContent: "center",
            }}
            onClick={() => {
              if (!filters.certified_only)
                setFilters({ ...filters, certified_only: "true" });
              else setFilters({ ...filters, certified_only: undefined });
            }}
          >
            <div className="d-flex flex-column align-items-center">
              <IconNew icon="check_circle" size={40} />
              <span className="fs-body-sm text-center">
                {t("ds.certified_only")}
              </span>
            </div>
          </div>
          <div
            className={`chip toggle ${
              filters.includes_benefits ? "selected" : ""
            }`}
            style={{
              borderRadius: "20px",
              width: "33.33%",
              flexDirection: "column",
              justifyContent: "center",
            }}
            onClick={() => {
              if (!filters.includes_benefits)
                setFilters({ ...filters, includes_benefits: "true" });
              else setFilters({ ...filters, includes_benefits: undefined });
            }}
          >
            <div className="d-flex flex-column align-items-center">
              <IconNew icon="ads_click" size={40} />
              <span className="fs-body-sm text-center">
                {t("ds.includes_benefits")}
              </span>
            </div>
          </div>
          <div
            className={`chip toggle ${
              filters.categories_filter?.includes("reviewscorebuckets::70")
                ? "selected"
                : ""
            }`}
            style={{
              borderRadius: "20px",
              width: "33.33%",
              flexDirection: "column",
              justifyContent: "center",
            }}
            onClick={() => {
              if (
                !filters.categories_filter?.includes("reviewscorebuckets::70")
              ) {
                setFilters(
                  updateCategoryFilter(
                    "reviewscorebuckets::70",
                    "reviewscorebuckets::70",
                    filters,
                  ),
                );
              } else {
                setFilters(
                  updateCategoryFilter("reviewscorebuckets::70", "", filters),
                );
              }
            }}
          >
            <div className="d-flex flex-column align-items-center">
              <IconNew icon="bookmark_star" size={40} />
              <span className="fs-body-sm text-center">
                {t("ds.minimum_score")}
              </span>
            </div>
          </div>
        </div>

        <div className="horizontal-divider" style={{ margin: "26px 0" }}></div>

        <p className="fs-body-lg medium">{t("ds.price_filter")}</p>
        <div className="height-26"></div>

        <Slider
          min={0}
          max={100}
          onChange={(min, max) => {
            setSliderMin(sliderToPrice(min));
            setSliderMax(sliderToPrice(max));
          }}
          onChangeEnd={(min, max) => {}}
          values={{
            min: priceToSlider(sliderMin),
            max: priceToSlider(sliderMax),
          }}
        />
        <div className="height-26"></div>

        <div className="d-flex gap-3 justify-content-between">
          <div>
            <span className="fs-body-xs">{t("ds.filter_price_min")}</span>
            <input
              className={isSmallScreen ? "fs-body" : "fs-body-sm"}
              onChange={(e) => {
                const num = Number(e.target.value.replace("€", ""));
                setSliderMin(isNaN(num) ? MIN_PRICE : num);
              }}
              onBlur={priceFilterValidation}
              type="text"
              value={"€" + sliderMin.toFixed(0)}
            />
          </div>
          <div>
            <span className="fs-body-xs">{t("ds.filter_price_max")}</span>
            <input
              className={isSmallScreen ? "fs-body" : "fs-body-sm"}
              onChange={(e) => {
                const num = Number(e.target.value.replace("€", ""));
                setSliderMax(isNaN(num) ? MAX_PRICE : num);
              }}
              onBlur={priceFilterValidation}
              type="text"
              value={"€" + sliderMax.toFixed(0)}
            />
          </div>
        </div>

        <div className="horizontal-divider" style={{ margin: "26px 0" }}></div>

        <p className="fs-body-lg medium d-flex align-items-center gap-2">
          <span>
            <IconNew icon="booking" size={24} style={{ fill: "#F6A90E" }} />
          </span>
          {t("ds.score_filter")}
        </p>
        <div className="height-16"></div>

        <div className="d-flex flex-wrap gap-3">
          {[
            "reviewscorebuckets::90",
            "reviewscorebuckets::80",
            "reviewscorebuckets::70",
            "reviewscorebuckets::60",
          ].map((score) => {
            return (
              <SelectableButton
                selected={!!filters.categories_filter?.includes(score)}
                onClick={() => {
                  if (!filters.categories_filter?.includes(score))
                    setFilters(
                      updateCategoryFilter(
                        "reviewscorebuckets",
                        score,
                        filters,
                      ),
                    );
                  else
                    setFilters(
                      updateCategoryFilter("reviewscorebuckets", "", filters),
                    );
                }}
              >
                <div className="d-flex align-items-center justify-content-center">
                  <IconNew
                    icon="star_fill"
                    size={25}
                    style={{ fill: "#F6A90E" }}
                  />
                  <span>{t("ds.score_" + score.split("::")[1])}</span>
                </div>
              </SelectableButton>
            );
          })}
        </div>

        <div className="horizontal-divider" style={{ margin: "26px 0" }}></div>

        <p className="fs-body-lg medium">{t("ds.distance_filter")}</p>
        <div className="height-16"></div>

        <div className="d-flex gap-2 align-items-center fs-body-sm">
          <div>
            <input
              id="distance_1"
              type="checkbox"
              checked={filters.categories_filter?.includes("distance::1000")}
              onChange={() => {
                if (filters.categories_filter?.includes("distance::1000"))
                  setFilters(updateCategoryFilter("distance", "", filters));
                else
                  setFilters(
                    updateCategoryFilter("distance", "distance::1000", filters),
                  );
              }}
            />
          </div>
          <label htmlFor="distance_1">{t("ds.distance_1")}</label>
        </div>
        <div className="height-10"></div>
        <div className="d-flex gap-2 align-items-center fs-body-sm">
          <div>
            <input
              id="distance_3"
              type="checkbox"
              checked={filters.categories_filter?.includes("distance::3000")}
              onChange={() => {
                if (filters.categories_filter?.includes("distance::3000"))
                  setFilters(updateCategoryFilter("distance", "", filters));
                else
                  setFilters(
                    updateCategoryFilter("distance", "distance::3000", filters),
                  );
              }}
            />
          </div>
          <label htmlFor="distance_3">{t("ds.distance_3")}</label>
        </div>
        <div className="height-10"></div>
        <div className="d-flex gap-2 align-items-center fs-body-sm">
          <div>
            <input
              id="distance_5"
              type="checkbox"
              checked={filters.categories_filter?.includes("distance::5000")}
              onChange={() => {
                if (filters.categories_filter?.includes("distance::5000"))
                  setFilters(updateCategoryFilter("distance", "", filters));
                else
                  setFilters(
                    updateCategoryFilter("distance", "distance::5000", filters),
                  );
              }}
            />
          </div>
          <label htmlFor="distance_5">{t("ds.distance_5")}</label>
        </div>
        <br />

        <div>
          <div
            className="horizontal-divider"
            style={{ margin: "26px 0" }}
          ></div>
          <PillsFilter
            title={"ds.facilities"}
            filterList={facility_generals}
            filters={filters}
            setFilters={setFilters}
            filterKey="hotelfacility"
          />
          <div
            className="horizontal-divider"
            style={{ margin: "26px 0" }}
          ></div>
          <PillsFilter
            title={"ds.facility_comforts"}
            filterList={facility_comforts}
            filters={filters}
            setFilters={setFilters}
            filterKey="hotelfacility"
          />
          <div
            className="horizontal-divider"
            style={{ margin: "26px 0" }}
          ></div>
          <PillsFilter
            title={"ds.facility_transports"}
            filterList={facility_transports}
            filters={filters}
            setFilters={setFilters}
            filterKey="hotelfacility"
          />
        </div>

        <FiltersAccordion filters={filters} setFilters={setFilters} />
        <br />
      </div>
      <div>
        <div className="horizontal-divider" style={{ margin: "0" }}></div>
        <div className="d-flex justify-content-between align-items-center p-3">
          <p
            className="fs-body underline cursor-pointer"
            onClick={() => {
              setSliderMin(MIN_PRICE);
              setSliderMax(MAX_PRICE);
              setFilters({ ...defaultFilters });
            }}
          >
            {t("ds.clear_filters")}
          </p>
          <Button
            text={t("ds.show_results_filter")}
            onClick={() => {
              // const params = Object.entries(filters)
              //   .map(
              //     ([key, value]) =>
              //       `${key}:${
              //         Array.isArray(value) ? value.join("|") : value
              //       }`,
              //   )
              //   .join(",");
              fireTagManagerEvent("filter_results", filters);

              onFilter(filters);
            }}
          />
        </div>
      </div>
    </>
  );
}
