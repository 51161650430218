import { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { SMALL_SCREEN } from "../../../assets/Utils/generic";
import { useTranslation } from "react-i18next";

export default function TalksAnimation() {
  const { t } = useTranslation();

  const hotel_dialogs = [
    t("ds.hotel_dialog_1"),
    t("ds.hotel_dialog_2"),
    t("ds.hotel_dialog_3"),
  ];

  const customer_dialogs = [
    t("ds.customer_dialog_1"),
    t("ds.customer_dialog_2"),
    t("ds.customer_dialog_3"),
  ];

  const containerRef = useRef<HTMLDivElement>(null);
  const isSmallScreen = useMediaQuery({ query: SMALL_SCREEN });
  const minSize = 200;
  const [containerWidth, setContainerWidth] = useState(
    isSmallScreen ? 500 : 630,
  );
  const [maxSize, setMaxSize] = useState(containerWidth - minSize);

  const [isHotelTaking, setIsHotelTaking] = useState(true);
  const [currentHotelDialog, setCurrentHotelDialog] = useState(0);
  const [currentCustomerDialog, setCurrentCustomerDialog] = useState(0);
  const [displayedText, setDisplayedText] = useState("");
  const [isTyping, setIsTyping] = useState(false);

  const currentHotelText =
    hotel_dialogs[currentHotelDialog] || hotel_dialogs[0];
  const currentCustomerText =
    customer_dialogs[currentCustomerDialog] || customer_dialogs[0];

  useEffect(() => {
    const interval = setInterval(() => {
      setIsHotelTaking((prev) => !prev);
      if (isHotelTaking) {
        setCurrentHotelDialog((prev) => (prev + 1) % hotel_dialogs.length);
      } else {
        setCurrentCustomerDialog(
          (prev) => (prev + 1) % customer_dialogs.length,
        );
      }
    }, 8000);

    return () => clearInterval(interval);
  }, [isHotelTaking]);

  useEffect(() => {
    const text = isHotelTaking ? currentHotelText : currentCustomerText;

    // Avvia l'effetto solo per il balloon attivo e quando è espanso
    if (maxSize > 76) {
      setIsTyping(true);
      setDisplayedText(text.charAt(0));

      let index = 1;
      const typingInterval = setInterval(() => {
        if (index < text.length) {
          setDisplayedText(text.substring(0, index + 1));
          index++;
        } else {
          setIsTyping(false);
          clearInterval(typingInterval);
        }
      }, 50);

      return () => clearInterval(typingInterval);
    }
  }, [isHotelTaking, currentHotelText, currentCustomerText, maxSize]);

  // Determina quale testo mostrare per ciascun balloon
  const hotelText = isHotelTaking ? displayedText : currentHotelText;
  const customerText = !isHotelTaking ? displayedText : currentCustomerText;

  return (
    <div className="w100 d-flex justify-content-center">
      <div
        ref={containerRef}
        className={`container w100 ${
          !isSmallScreen ? "d-flex justify-content-between" : ""
        }`}
        style={{ maxWidth: "630px" }}
      >
        {/* Hotels */}
        <div
          className={`hotel position-relative${isSmallScreen ? " w100" : ""}`}
        >
          <div
            className={`hotel-person rounded-circle${
              isHotelTaking ? " talk" : ""
            }`}
            style={{
              backgroundColor: "#454545",
              width: "36px",
              height: "36px",
            }}
          ></div>
          <div
            className={`hotel-baloon px-3 py-1 baloonTalk rounded-5${
              isHotelTaking ? " baloonTalkOpened" : ""
            }`}
            style={{
              left: "45px",
              width: isHotelTaking ? maxSize + "px" : "76px",
            }}
          >
            <div
              className={`balloon ${isHotelTaking ? "hotel" : "customer"} ${
                isHotelTaking && isTyping ? "typing" : ""
              }`}
            >
              {hotelText}
            </div>
          </div>
        </div>
        {/* Customers */}
        <div
          className={`customer position-relative d-flex${
            isSmallScreen ? " w100 justify-content-end mt-5" : ""
          }`}
        >
          <div
            className={`customer-baloon px-3 py-1 baloonTalk rounded-5${
              !isHotelTaking ? " baloonTalkOpened" : ""
            }`}
            style={{
              right: "45px",
              width: !isHotelTaking ? maxSize + "px" : "76px",
            }}
          >
            <div
              className={`balloon ${isHotelTaking ? "hotel" : "customer"} ${
                !isHotelTaking && isTyping ? "typing" : ""
              }`}
            >
              {customerText}
            </div>
          </div>
          <div
            className={`customer-person rounded-circle${
              !isHotelTaking ? " talk" : ""
            }`}
            style={{
              backgroundColor: "#454545",
              width: "36px",
              height: "36px",
              order: 2,
            }}
          ></div>
        </div>
      </div>
    </div>
  );
}
