export default function SelectableButton({
  selected,
  onClick,
  children,
}: {
  selected: boolean;
  onClick: () => void;
  children: JSX.Element | string;
}) {
  return (
    <div
      className={`chip toggle ${selected ? "selected" : ""}`}
      onClick={onClick}
    >
      <p className="fs-body-sm">{children}</p>
    </div>
  );
}
