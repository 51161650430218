import { useCallback, useMemo } from "react";
import { SearchParameters } from "../../../assets/Services/metasearch";
import Chip from "../../../assets/TakyonDesignSystem/components/Chip/Chip";
import { updateCategoryFilter } from "./FiltersModal";
import { useTranslation } from "react-i18next";
import ScrollContainer from "../../../assets/TakyonDesignSystem/components/ScrollContainer/ScrollContainer";
import { MAX_PRICE, MIN_PRICE, search } from "./Search";
import { useNavigate } from "react-router-dom";

type Filter = { filter: string; value: any };

export default function FilterTags({ params }: { params: SearchParameters }) {
  const filters = useMemo(() => decodeFilters(params), [params]);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onDelete = useCallback(
    (f: Filter) => {
      if (Object.keys(params).includes(f.filter))
        search({ ...params, [f.filter]: undefined }, navigate);
      else search(updateCategoryFilter(f.filter, "", params, true), navigate);
    },
    [params],
  );

  const getFilterLabel = useCallback(
    (f: Filter) => {
      if (f.filter === "price_min") {
        return `${t("ds.filter_price_min")}: €${f.value}`;
      } else if (f.filter === "price_max") {
        return `${t("ds.filter_price_max")}: €${f.value}`;
      } else if (f.filter === "certified_only") return t("ds.certified_only");
      else if (f.filter === "includes_benefits") {
        return t("ds.includes_benefits");
      } else if (f.filter.includes("reviewscorebuckets")) {
        const score = f.value.split("::")[1];
        return `${t("ds.reviewscorebuckets")}: ${t(`ds.score_${score}`)}`;
      } else if (f.filter.includes("distance")) {
        const distance = Math.round(Number(f.value.split("::")[1]) / 1000);
        return t(`ds.distance_${distance}`);
      } else if (f.filter.includes("class")) {
        const class_number = Number(f.value.split("::")[1]);
        return t(`class.${class_number}stars`);
      } else if (f.filter.includes("accessible_facilities")) {
        const facility_number = Number(f.value.split("::")[1]);
        return t(
          `accessible_facilities.${filter_accessible_facilities[facility_number]}`,
        );
      } else if (f.filter.includes("mealplan")) {
        const facility_number = Number(f.value.split("::")[1]);
        return t(`mealplan.${filter_meals[facility_number]}`);
      } else if (f.filter.includes("fc")) {
        const facility_number = Number(f.value.split("::")[1]);
        return t(`fc.${filter_booking_rules[facility_number]}`);
      } else if (f.filter.includes("property_type")) {
        const facility_number = Number(f.value.split("::")[1]);
        return t(`property_type.${property_types[facility_number]}`);
      } else if (f.filter.includes("hotelfacility")) {
        const facility_number = Number(f.value.split("::")[1]);
        return t(
          `hotelfacility.${
            facility_generals[facility_number] ||
            facility_comforts[facility_number] ||
            facility_transports[facility_number]
          }`,
        );
      }

      return "";
    },
    [t],
  );

  return (
    <ScrollContainer
      isLightTheme={true}
      content={
        <>
          {filters.map((f) => (
            <Chip
              key={"chip" + f.filter}
              label={getFilterLabel(f)}
              onDelete={() => onDelete(f)}
            />
          ))}
        </>
      }
    ></ScrollContainer>
  );
}

function decodeFilters(params: SearchParameters): Filter[] {
  const filters: Filter[] = [];

  const min_pirce = Number(params.price_min);
  if (min_pirce > MIN_PRICE)
    filters.push({ filter: "price_min", value: min_pirce });
  const max_pirce = Number(params.price_max);
  if (max_pirce < MAX_PRICE)
    filters.push({ filter: "price_max", value: max_pirce });

  if (params.certified_only === "true")
    filters.push({ filter: "certified_only", value: true });
  if (params.includes_benefits === "true")
    filters.push({ filter: "includes_benefits", value: true });

  const review_score_match = params.categories_filter?.match(
    /reviewscorebuckets::(\d+)/g,
  );
  if (review_score_match)
    filters.push(...review_score_match.map((m) => ({ filter: m, value: m })));

  const distance_match = params.categories_filter?.match(/distance::(\d+)/g);
  if (distance_match)
    filters.push(...distance_match.map((m) => ({ filter: m, value: m })));

  const facility_match =
    params.categories_filter?.match(/hotelfacility::(\d+)/g) || [];
  if (facility_match)
    filters.push(...facility_match.map((m) => ({ filter: m, value: m })));

  const property_type_match =
    params.categories_filter?.match(/property_type::(\d+)/g) || [];
  if (property_type_match.length < 8)
    filters.push(...property_type_match.map((m) => ({ filter: m, value: m })));

  // Stars
  const stars_match = params.categories_filter?.match(/class::(\d+)/g) || [];
  if (stars_match)
    filters.push(...stars_match.map((m) => ({ filter: m, value: m })));

  // Accessible facilities
  const accessible_facilities_match =
    params.categories_filter?.match(/accessible_facilities::(\d+)/g) || [];
  if (accessible_facilities_match)
    filters.push(
      ...accessible_facilities_match.map((m) => ({ filter: m, value: m })),
    );

  // Meals
  const meals_match = params.categories_filter?.match(/mealplan::(\d+)/g) || [];
  if (meals_match)
    filters.push(...meals_match.map((m) => ({ filter: m, value: m })));

  // Booking rules
  const booking_rules_match =
    params.categories_filter?.match(/fc::(\d+)/g) || [];
  if (booking_rules_match)
    filters.push(...booking_rules_match.map((m) => ({ filter: m, value: m })));

  return filters;
}

export const facility_generals: Record<number, string> = {
  107: "free_wifi",
  8: "24_hour_front_desk",
  28: "family_rooms",
  16: "non_smoking_rooms",
  4: "pets_allowed",
  3: "restaurant",
  72: "bbq_facilities",
  25: "facilities_for_disabled_guests",
};

export const facility_comforts: Record<number, string> = {
  5: "room_service",
  11: "fitness_centre",
  54: "spa_and_wellness_centre",
  433: "swimming_pool",
};

export const facility_transports: Record<number, string> = {
  2: "parking",
  17: "airport_shuttle",
  46: "free_parking",
  139: "airport_shuttle_free",
  182: "electric_vehicle_charging_station",
};

export const property_types: Record<number, string> = {
  204: "hotel",
  203: "hostel",
  205: "motel",
  208: "b&b",
  225: "capsule_hotels",
  206: "resort",
  221: "lodges",
  226: "love_hotels",
};

export const filter_class: Record<number, string> = {
  1: "1stars",
  2: "2stars",
  3: "3stars",
  4: "4stars",
  5: "5stars",
};
export const filter_accessible_facilities: Record<number, string> = {
  186: "wc_with_handles",
  187: "wc_high_seat",
  188: "low_washbasin",
  189: "bath_with_bell",
  211: "visual_ads_braille",
  212: "visual_ads_tactile",
  213: "visual_ads_audio",
};

export const filter_meals: Record<number, string> = {
  1: "breakfast_included",
  9: "breakfast_lunch_included",
  999: "kitchen",
};

export const filter_booking_rules: Record<number, string> = {
  2: "fc",
};
