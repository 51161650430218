import { useTranslation } from "react-i18next";
import { publicContact } from "../../../assets/models/iCollection";
import IconNew from "../../../assets/TakyonDesignSystem/components/Icon/Icon";
import { closeModal } from "../../../assets/Utils/modal";

export default function HotelContacts({
  hotelContacts,
}: {
  hotelContacts: publicContact[];
}) {
  const { t } = useTranslation();
  return (
    <div className="d-flex flex-column w100">
      <p className="mb-3 medium">{t("ds.hotel_contacts_title")}</p>
      <div className="d-flex flex-column gap-3">
        {hotelContacts.map((contact, index) => {
          switch (contact.type) {
            case "phone":
            case "whatsapp":
              return (
                <div
                  key={index}
                  className="d-flex align-items-center gap-2 text-black"
                >
                  <div className="d-flex p-1 border rounded-2 border-black">
                    <IconNew icon="call" type="symbol" />
                  </div>
                  <a
                    className="fs-body-sm text-black"
                    href={`tel:${contact.value}`}
                  >
                    {contact.value}
                  </a>
                </div>
              );
            case "email":
              return (
                <div key={index} className="d-flex align-items-center gap-2">
                  <div className="d-flex p-1 border rounded-2 border-black">
                    <IconNew icon="stacked_email" type="symbol" />
                  </div>
                  <a
                    className="fs-body-sm text-black"
                    href={`mailto:${contact.value}`}
                  >
                    {contact.value}
                  </a>
                </div>
              );
            case "website":
              return (
                <div key={index} className="d-flex align-items-center gap-2">
                  <div className="d-flex p-1 border rounded-2 border-black">
                    <IconNew icon="captive_portal" type="symbol" />
                  </div>
                  <a
                    className="fs-body-sm text-black"
                    target="_blank"
                    href={contact.value}
                  >
                    {contact.value}
                  </a>
                </div>
              );
            default:
              return null;
          }
        })}
      </div>
    </div>
  );
}
