import React, { useState, useEffect, useMemo } from "react";
import Icon from "../../../assets/TakyonDesignSystem/components/Icon/Icon";
import { motion, AnimatePresence } from "framer-motion";
import {
  getBookingComCollection,
  Hotel,
} from "../../../assets/Services/metasearch";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { addDays, format, nextFriday } from "date-fns";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import IconNew from "../../../assets/TakyonDesignSystem/components/Icon/Icon";
import Stars from "../../../assets/TakyonDesignSystem/components/Stars/Stars";
import { useMediaQuery } from "react-responsive";
import { LARGE_SCREEN, SMALL_SCREEN } from "../../../assets/Utils/generic";
import Button from "../../../assets/TakyonDesignSystem/components/Button/Button";
import { openModal } from "../../../assets/Utils/modal";
import PhotoGallery from "./PhotoGallery";
import { getLocaleLanguage } from "../../../assets/Services/i18next";
import { Reviews } from "./Results";
import HotelsMap from "./HotelsMap";
import CollectionController from "../../../assets/Controllers/CollectionController";
import { fireTagManagerEvent } from "../../../assets/Services/tagmanager";
import HotelContacts from "./HotelContacts";

interface Props {
  hotelId: string;
  onClose: any;
  directPrice: number;
  bookingPrice: number;
  hotelsPrice: number;
  isScroll: boolean;
  dest_label: string;
  searchParams: any;
  setCurrentSelected(id: string): void;
}

interface iPagination<T> {
  data: T[];
  total: number;
  page: number;
  size: number;
}

export default function ModalDetailHotel({
  hotelId,
  onClose,
  directPrice,
  bookingPrice,
  hotelsPrice,
  isScroll,
  dest_label,
  searchParams,
  setCurrentSelected,
}: Props) {
  const [isClosing, setIsClosing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [hotel, setHotelData] = useState<Hotel>();
  const isSmallScreen = useMediaQuery({ query: SMALL_SCREEN });
  const isLargeScreen = useMediaQuery({ query: LARGE_SCREEN });
  const isMaxWidth1200 = useMediaQuery({ query: "(max-width: 1279px)" });

  const [noCertLink, setNoCertLink] = useState<string>("");
  const [currentLanguageCode, setCurrentLanguageCode] = useState<string>("");
  const { i18n } = useTranslation();

  useEffect(() => {
    const fetchLanguage = async () => {
      setCurrentLanguageCode(await getLocaleLanguage());
    };
    fetchLanguage();
  }, [i18n.language]);

  useEffect(() => {
    document
      .getElementById("modal-detail-body")
      ?.scrollTo({ top: 0, behavior: "smooth" });

    const fetchData = async () => {
      try {
        let noCertLinkLng = "";
        if (currentLanguageCode !== "it") {
          noCertLinkLng += `/${currentLanguageCode}`;
        }

        setNoCertLink("https://takyon.io/b/partner-form" + noCertLinkLng);

        if (currentLanguageCode === "") {
          setIsLoading(true);
          return;
        }

        const formattedLanguageCode =
          currentLanguageCode === "en" ? "en-us" : currentLanguageCode;
        setIsLoading(true);
        const today = new Date();
        const nextWeekendStart = nextFriday(today);
        const nextWeekendEnd = addDays(nextWeekendStart, 2); // Sunday
        const data = await getBookingComCollection({
          hotel_id: hotelId,
          languagecode: formattedLanguageCode,
          arrival_date: format(nextWeekendStart, "yyyy-MM-dd"),
          departure_date: format(nextWeekendEnd, "yyyy-MM-dd"),
        });
        setHotelData(data);
      } catch (error) {
        console.error("Error fetching hotel data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (hotelId) {
      fetchData();
    }
  }, [hotelId, currentLanguageCode]);

  const handleClose = () => {
    setCurrentSelected("");
    setIsClosing(true);
  };

  let showTopChoice = false;
  if (hotel?._collection) {
    if (!hotel._collection.isFree) showTopChoice = true;
  }

  const address = hotel?._collection?.census?.location?.label;
  const locationAccess0 = hotel?.accessibilityLabel;
  const locationAccess1 = hotel?.accessibilityLabel
    ?.split("‎")[1]
    ?.split("‬")[0];
  const locationAccess2 = hotel?.accessibilityLabel
    ?.split("‎")[2]
    ?.split("‬")[0];

  const websiteUrl = hotel?._collection?.census?.website
    ? hotel._collection.census.website.startsWith("http")
      ? hotel._collection.census.website
      : `https://${hotel._collection.census.website}`
    : undefined;

  let advantages = hotel?._collection?.preData?.advantagesOnDirectWebsite;
  if (advantages) {
    advantages = advantages.slice(0, 3); // Keep only the first three elements
  }

  const showCertified = hotel?._collection ? true : false;

  const cutPrice = Math.min(bookingPrice, hotelsPrice);

  const urlGoogle = `https://google.com/search?q=${encodeURIComponent(
    `${hotel?.property.name.replace(/&/g, "")} ${dest_label?.split(",")[0]}`,
  )}`;

  let urlWebsite = hotel?._collection?.census?.website;
  if (urlWebsite && !urlWebsite.includes("http"))
    urlWebsite = `https://${urlWebsite}`;

  const fallbackUrl = urlWebsite ?? urlGoogle;

  const questionScores = useMemo(() => {
    try {
      return hotel?.property.scores.score_breakdown
        .find((breakdown) => breakdown.customer_type === "total")
        ?.question?.slice(0, 6);
    } catch (e) {}
  }, [hotel]);

  const reviewsToShow = useMemo(() => {
    try {
      return hotel?.property.reviews
        .sort((a, b) => (b.pros?.length || 0) - (a.pros?.length || 0))
        .slice(0, 2);
    } catch (e) {}
  }, [hotel]);

  const facilitiesToShow = useMemo(() => {
    try {
      return hotel?.property?.facilities?.slice(0, 10);
    } catch (e) {}
  }, [hotel]);

  return (
    <AnimatePresence>
      {hotelId !== "" && (
        <motion.div
          className={`w100 overflow-hidden  ${
            isMaxWidth1200 ? "position-fixed" : "position-absolute"
          } bg-white shadow`}
          style={{
            zIndex: 99,
            height: isMaxWidth1200 ? "calc(100dvh - 63px)" : "100%",
            top: isMaxWidth1200 ? "63px" : undefined,
            left: 0,
          }}
          initial={isMaxWidth1200 ? { y: "100%" } : { x: "100%" }}
          animate={
            isMaxWidth1200
              ? { y: isClosing ? "100%" : 0 }
              : { x: isClosing ? "100%" : 0 }
          }
          transition={
            isClosing
              ? {
                  type: "spring",
                  damping: 30,
                  stiffness: 200,
                  duration: 0.02,
                }
              : {
                  type: "spring",
                  damping: 20,
                  stiffness: 100,
                  duration: 0.2,
                }
          }
          onAnimationComplete={() => {
            if (isClosing) {
              onClose();
              setIsClosing(false);
            }
          }}
        >
          <div className="d-flex justify-content-end border-bottom px-4 py-3">
            <Icon
              icon="close"
              className="cursor-pointer"
              onClick={handleClose}
              size={24}
            />
          </div>
          <div
            id="modal-detail-body"
            className="p-4 overflow-hidden overflow-y-auto"
            style={{ height: "calc(100% - 57px)" }}
          >
            {isLoading || !hotel ? (
              <div>
                <Skeleton count={3} className="mb-2" />
                <Skeleton height={200} className="mb-4" />
                <Skeleton count={3} className="mb-2" />
                <Skeleton width={150} />
              </div>
            ) : (
              <div>
                {/* Top choice */}
                <div
                  className={`d-flex ${
                    showTopChoice
                      ? "justify-content-between"
                      : "justify-content-end"
                  } align-items-center mb-2`}
                >
                  {showTopChoice && (
                    <OverlayTrigger
                      placement="top"
                      flip={true}
                      overlay={<Tooltip>{t("ds.top_choice_popover")}</Tooltip>}
                    >
                      <div
                        className="black-background d-flex align-items-center cursor-pointer px-2 rounded"
                        style={{ width: "fit-content" }}
                      >
                        <IconNew
                          icon="top_partner"
                          size={16}
                          style={{ fill: "white" }}
                        />
                        <span className="fs-body-xs white-color text-nowrap m-1">
                          {t("ds.top_choice")}
                        </span>
                      </div>
                    </OverlayTrigger>
                  )}
                  {/* Review Score */}
                  <div className="d-flex align-items-center gap-1">
                    <div className="d-flex align-items-center gap-1">
                      <div style={{ width: "20px" }}>
                        <IconNew icon="star_fill" style={{ fill: "#F6A90E" }} />
                      </div>
                      <p
                        className="fs-h4 medium review-score underline cursor-pointer"
                        onClick={() =>
                          document
                            .getElementById("reviews")
                            ?.scrollIntoView({ behavior: "smooth" })
                        }
                      >
                        {hotel.property.reviewScore}
                      </p>
                    </div>
                  </div>
                </div>
                {/* Name and stars */}
                <div>
                  <p
                    className="fs-h4 medium mb-0 mr-2 d-inline cursor-pointer underline"
                    onClick={() => {
                      if (!hotel?.hotel_id) return;
                      const hotelElement = document.getElementById(
                        hotel?.hotel_id.toString(),
                      );
                      if (hotelElement) {
                        const yOffset = -80;
                        const y =
                          hotelElement.getBoundingClientRect().top +
                          window.pageYOffset +
                          yOffset;
                        window.scrollTo({ top: y, behavior: "smooth" });
                      }
                    }}
                  >
                    {hotel.property.name}
                    <Stars
                      margin="mx-2"
                      isFlex={false}
                      stars={hotel.property?.accuratePropertyClass || 0}
                      className="black-color d-inline"
                    />
                  </p>
                </div>
                {/* Location */}
                <div className="d-flex align-items-center">
                  <IconNew icon="location_on" color="#727272" />
                  <p
                    className="dark-grey-color fs-body-sm mt-1 cursor-pointer underline"
                    onClick={() =>
                      document
                        .getElementById("map")
                        ?.scrollIntoView({ behavior: "smooth" })
                    }
                  >
                    {address ? (
                      <>{address}</>
                    ) : locationAccess1 || locationAccess2 ? (
                      <>
                        {locationAccess1} - {locationAccess2}{" "}
                      </>
                    ) : (
                      <>{locationAccess0}</>
                    )}
                  </p>
                </div>
                {websiteUrl && (
                  <a
                    href={websiteUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="blue-color fs-body-sm mt-2 cursor-pointer underline"
                  >
                    {t("ds.visit_website")}
                  </a>
                )}
                {/* Images */}
                <div className="d-flex align-items-center mt-3">
                  {hotel?.property.images?.length > 0 && (
                    <div className="position-relative">
                      <div className="d-flex flex-wrap gap-2">
                        {(isSmallScreen
                          ? hotel.property.images.slice(0, 4)
                          : hotel.property.images.slice(0, 6)
                        ).map((url, index) => (
                          <img
                            key={index}
                            src={url.replace("square60", "square600")}
                            alt={`${hotel.property.name} - ${index + 1}`}
                            style={{
                              width: isSmallScreen
                                ? "calc(50% - 0.5rem)"
                                : "calc(33.33% - 0.5rem)",
                              aspectRatio: "1 / 0.8",
                              objectFit: "cover",
                            }}
                          />
                        ))}
                      </div>
                      <Button
                        variant="light"
                        size="sm"
                        text={t("ds.show_all")}
                        textClass="fs-body-sm"
                        className="position-absolute"
                        style={{ right: "15px", bottom: "10px" }}
                        onClick={() => {
                          openModal({
                            content: (
                              <div>
                                <div
                                  className="border-bottom"
                                  style={{ height: "40px" }}
                                ></div>
                                <div className="modal-body">
                                  {hotel.property.images?.length > 0 && (
                                    <div
                                      className={`d-flex flex-wrap gap-2 ${
                                        !isSmallScreen || !isLargeScreen
                                          ? "p-3"
                                          : ""
                                      }`}
                                    >
                                      {!isSmallScreen || !isLargeScreen ? (
                                        hotel.property.images.map(
                                          (url, index) => (
                                            <img
                                              key={index}
                                              src={url.replace(
                                                "square60",
                                                "square600",
                                              )}
                                              alt={`${hotel.property.name} - ${
                                                index + 1
                                              }`}
                                              style={{
                                                width: "100%",
                                                aspectRatio: "1 / 0.8",
                                                objectFit: "cover",
                                              }}
                                            />
                                          ),
                                        )
                                      ) : (
                                        <PhotoGallery
                                          images={hotel.property.images.map(
                                            (url) =>
                                              url.replace(
                                                "square60",
                                                "square600",
                                              ),
                                          )}
                                        />
                                      )}
                                    </div>
                                  )}
                                </div>
                              </div>
                            ),
                          });
                        }}
                      />
                    </div>
                  )}
                </div>
                {/* Pricing */}
                <div className="p-3 bg-light-grey rounded-2 border mt-4">
                  {showTopChoice && (
                    <>
                      <p className="fs-body medium mb-2">
                        {t("ds.why_direct")}
                      </p>
                      {advantages && advantages.length > 0 && (
                        <div className="d-flex flex-wrap gap-2">
                          {advantages.map((a, key) => (
                            <div
                              className="d-flex align-items-center gap-1"
                              key={key}
                            >
                              <div style={{ width: "16px" }}>
                                <IconNew
                                  icon="check_circle"
                                  fill
                                  size={16}
                                  style={{ fill: "green" }}
                                />
                              </div>
                              <p className="fs-body-sm light">
                                {t("ds." + a.replaceAll(" ", ""))}
                              </p>
                            </div>
                          ))}
                        </div>
                      )}
                      {/* <div className="border-top"></div> */}
                    </>
                  )}
                  {/* <div className="horizontal-divider my-2" />
                  <p className="fs-body-sm mb-3">
                    <span className="medium">Parola all'albergatore: </span>
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Nulla facilisi."
                  </p> */}
                </div>
                {/* NO Certificates */}
                {!showCertified && (
                  <div
                    className="p-3 rounded-2 border mt-4"
                    style={{
                      backgroundColor: "#edf3ff",
                      borderColor: "#4282FF",
                    }}
                  >
                    <p className="fs-body-sm">{t("ds.hotel_not_certified")}</p>
                    <p
                      className="fs-body-sm medium underline cursor-pointer mt-2"
                      onClick={() => window.open(noCertLink, "_blank")}
                    >
                      {t("ds.fill_form")}
                    </p>
                  </div>
                )}
                {/* Prices */}
                <p className="fs-body medium mt-4">{t("ds.compare_prices")}</p>
                <div className="mt-auto">
                  <div className="d-flex align-items-center justify-content-between p-2">
                    <p className="fs-body-sm medium">Booking.com</p>
                    <div className="d-flex gap-3">
                      <p className="fs-body medium text-red">
                        €{bookingPrice.toFixed(0)}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between p-2 border-top">
                    <p className="fs-body-sm medium">Hotels.com</p>
                    <div className="d-flex gap-3">
                      <p className="fs-body medium text-red">
                        €{bookingPrice.toFixed(0)}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between p-2 border-top border-bottom">
                    <p className="fs-body-sm medium">
                      {t("ds.direct_website")}
                    </p>
                    <div className="d-flex gap-3">
                      <p className="fs-body medium">
                        €{directPrice.toFixed(0)}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end gap-2 mt-3">
                    {/* <Button
                        variant="light"
                        textClass="fs-body-sm"
                        key="contact-hotel"
                        text={t("ds.contact_hotel")}
                      ></Button> */}
                    <Button
                      size="sm"
                      textClass="fs-body-sm"
                      text={
                        showCertified
                          ? t("ds.visit_webiste")
                          : t("ds.look_website")
                      }
                      onClick={() => {
                        CollectionController.clickOnCollection(
                          hotel._collection?._id ??
                            `${hotel.property.name}-${hotel.property.id}`,
                          {
                            btn: "visit_website",
                            directPrice,
                            cutPrice,
                            bookingPrice,
                            hotelsPrice,
                            bookingComId: hotel.property.id,
                            checkin: hotel.property.checkinDate,
                            checkout: hotel.property.checkoutDate,
                          },
                        );
                        fireTagManagerEvent("book_on_site", {
                          name: hotel.property?.name,
                          bookingcom_id: hotel.hotel_id,
                          collection_id: hotel._collection?._id,
                          isFree: hotel._collection?.isFree,
                          amount: directPrice.toFixed(0),
                          destination: dest_label,
                          dates:
                            hotel.property.checkinDate +
                            " - " +
                            hotel.property.checkoutDate,
                        });

                        const newTabUrl =
                          "/directsearch/redirect?" +
                          new URLSearchParams({
                            ...searchParams,
                            collectionId:
                              hotel._collection?._id.toString() || "",
                            fallbackUrl,
                          });

                        window.open(newTabUrl, "_blank");
                      }}
                    ></Button>
                  </div>
                </div>
                <div className="horizontal-divider"></div>
                {/* Description */}
                {/* <div>
                  <p className="fs-body medium">{t("ds.description")}</p>
                  <p className="fs-body-sm mt-3">
                    {hotel.property?.description[0].description}
                  </p>
                </div> */}
                {/* Arrivals / Departures */}
                <div className="p-2 bg-light-grey rounded-2 border mt-4 d-flex flex-wrap justify-content-between align-items-center">
                  <div className="d-flex align-items-center gap-2">
                    <IconNew icon="text_select_jump_to_end" size={24} />
                    <p className="fs-body-sm">
                      <span className="medium">{t("ds.arrival")}: </span>{" "}
                      {t("ds.from")} {hotel.property?.checkin?.fromTime}
                    </p>
                  </div>
                  <div className="d-flex align-items-center gap-2">
                    <IconNew icon="text_select_jump_to_beginning" size={24} />
                    <p className="fs-body-sm">
                      <span className="medium">{t("ds.departure")}: </span>{" "}
                      {t("ds.until")} {hotel.property?.checkout?.untilTime}
                    </p>
                  </div>
                </div>
                <div className="horizontal-divider"></div>
                {/* Facilities */}
                <p className="fs-body medium mb-2">{t("ds.best_facilities")}</p>
                <div className="d-flex flex-wrap gap-2">
                  {facilitiesToShow?.map((f, index) => (
                    <div
                      key={index}
                      className="d-inline-flex align-items-center px-2 py-0 rounded-pill border gap-2"
                    >
                      <p className="fs-body-sm light m-0">
                        {f.instances[0].title}
                      </p>
                    </div>
                  ))}
                </div>
                <div className="horizontal-divider"></div>
                {/* Contact the hotel */}
                {hotel._collection?.publicContacts &&
                  hotel._collection?.publicContacts.length > 0 && (
                    <>
                      <HotelContacts
                        hotelContacts={hotel._collection?.publicContacts ?? []}
                      />
                      <div className="horizontal-divider"></div>
                    </>
                  )}
                {/* Reviews */}
                <div id="reviews" className="d-flex align-items-center">
                  <IconNew icon="booking" className="mx-2" size={20} />
                  <p className="fs-body medium">{t("ds.guests_reviews")}</p>
                </div>
                <div className="d-flex align-items-center gap-1">
                  <div className="d-flex align-items-center gap-1">
                    <div style={{ width: "20px" }}>
                      <IconNew icon="star_fill" style={{ fill: "#F6A90E" }} />
                    </div>
                    <p
                      className="fs-h4 medium review-score"
                      style={{ marginLeft: "8px" }}
                    >
                      {hotel.property.reviewScore}
                    </p>
                    <p className="fs-body light review-summary">
                      {hotel.property.reviewScoreWord} (
                      <span
                        onClick={() => {
                          openModal({ content: <Reviews hotel={hotel} /> });
                        }}
                        className="cursor-pointer underline"
                      >
                        {hotel.property.reviewCount} {t("ds.reviews")}
                      </span>
                      )
                    </p>
                  </div>
                </div>
                {/* Score Percentage */}
                <div className="my-4">
                  <div className="row g-3">
                    {questionScores?.map((item, index) => (
                      <div key={index} className="col-6">
                        <div className="d-flex justify-content-between mb-1">
                          <p className="fs-body-xs m-0 medium text-truncate">
                            {item.localized_question}
                          </p>
                          <p className="fs-body-xs m-0 medium">{item.score}</p>
                        </div>
                        <div className="progress" style={{ height: "4px" }}>
                          <div
                            className="progress-bar"
                            role="progressbar"
                            style={{
                              width: `${(item.score / 10) * 100}%`,
                              backgroundColor: "#85DB7F",
                            }}
                            aria-valuenow={item.score}
                            aria-valuemin={0}
                            aria-valuemax={10}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                {/* Reviews */}
                <div style={{ marginTop: "36px" }}></div>
                {reviewsToShow?.map((rev, index) => (
                  <div
                    key={index}
                    className="border rounded-2 p-3"
                    style={{ marginBottom: "12px" }}
                  >
                    <div className="d-flex justify-content-between gap-4">
                      <div>
                        <div className="height-4"></div>
                        <p className="fs-body medium">
                          {rev.title_translated || rev.title}
                        </p>
                        <div className="height-4"></div>
                        <p className="fs-body-sm">{rev.author.name}</p>
                        <div className="height-4"></div>
                      </div>
                      <div>
                        <div
                          className="fs-body-lg black-background white-color medium p-1 text-center"
                          style={{ borderRadius: "5px" }}
                        >
                          {Math.min(rev.average_score * 2.5, 10).toFixed(0)}
                        </div>
                      </div>
                    </div>
                    <div className="fs-body-sm mt-2">
                      {rev.pros_translated || rev.pros}
                    </div>
                  </div>
                ))}
                <div className="d-flex justify-content-end">
                  <p
                    className="fs-body-sm medium underline cursor-pointer"
                    onClick={() => {
                      openModal({ content: <Reviews hotel={hotel} /> });
                    }}
                  >
                    {t("ds.read_guests_reviews")}
                  </p>
                </div>
                <div className="horizontal-divider"></div>
                {/* Map */}
                <p id="map" className="fs-body medium">
                  {t("ds.address")}
                </p>
                <p className="dark-grey-color fs-body mt-1">
                  {address ? (
                    <>{address}</>
                  ) : locationAccess1 || locationAccess2 ? (
                    <>
                      {locationAccess1} - {locationAccess2}{" "}
                    </>
                  ) : (
                    <>{locationAccess0}</>
                  )}
                </p>
                <div
                  className="my-3"
                  style={{
                    height: "364px",
                    width: "100%",
                  }}
                >
                  <HotelsMap
                    hotels={[hotel]}
                    selected={String(hotelId)}
                    onClick={() => {}}
                    hotelPrice={directPrice}
                  />
                </div>
              </div>
            )}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
