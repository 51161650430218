import Icon from "../Icon/Icon";
export interface ChipProps {
  label: string;
  onDelete?: () => void;
}
export default function Chip({ label, onDelete }: ChipProps) {
  return (
    <div className="chip">
      <span className={"bodytext chip__label"}>{label}</span>
      {
        <Icon
          className="cursor-pointer"
          icon="cancel"
          onClick={onDelete}
        ></Icon>
      }
    </div>
  );
}
