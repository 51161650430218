import TakLogo from "../../images/TakyonShortLight.svg";

export default function TakShortId(props: { shortId: string }) {
  return (
    <div className="d-flex align-items-center bg-light-black rounded p-1 px-2">
      <div>
        <img
          style={{
            width: "36px",
            height: "13px",
            transform: "translateY(-2px)",
          }}
          src={TakLogo}
          alt="Takyon"
        />
      </div>
      <span className="light" style={{ fontSize: "10.4px" }}>
        {props?.shortId ? "#" + props?.shortId : null}
      </span>
    </div>
  );
}
