import {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  forwardRef,
} from "react";
import {
  BookingComReviews,
  BookingComSearch,
  Hotel,
  Review,
  SearchParameters,
} from "../../../assets/Services/metasearch";
import IconNew from "../../../assets/TakyonDesignSystem/components/Icon/Icon";
import { useTranslation } from "react-i18next";
import Stars from "../../../assets/TakyonDesignSystem/components/Stars/Stars";
import Button from "../../../assets/TakyonDesignSystem/components/Button/Button";

import Search, { search as newSearch } from "./Search";
import { openModal } from "../../../assets/Utils/modal";
import {
  datetimeToString,
  SMALL_SCREEN,
  LARGE_SHORT_SCREEN,
  x_LARGE_SCREEN,
  sleep,
} from "../../../assets/Utils/generic";
import { fireTagManagerEvent } from "../../../assets/Services/tagmanager";
import { getLocaleLanguage } from "../../../assets/Services/i18next";
import { LANG } from "../../../config/Lang";
import { useMediaQuery } from "react-responsive";
import { useNavigate, useSearchParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

import Loader from "../../components/Loader/Loader";
import no_results from "../../../assets/img/directsearch/no_results.png";
import useRandomLoad from "../../hooks/useRandomLoad";
import ResultSkeletonList from "./Skeleton/ResultSkeletonList";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import HotelsMap from "./HotelsMap";
import ModalDetailHotel from "./ModalDetailHotel";
import CollectionController from "../../../assets/Controllers/CollectionController";
import { useSelector } from "react-redux";
import { selectUser } from "../../../redux/slices/userSlice";
import Input from "../../../assets/TakyonDesignSystem/components/Input/Input";
import { Form } from "react-bootstrap";
import HotelContacts from "./HotelContacts";
import ImpressionsController from "../../../assets/Controllers/ImpressionsController";

// http://localhost:3000/directsearch/search?dest_id=-2092174&search_type=CITY&arrival_date=2025-01-15&departure_date=2025-01-16&adults=1&children_age=0%2C17&room_qty=1&page_number=1&units=metric&temperature_unit=c&languagecode=it&currency_code=EUR

export const Reviews = memo(({ hotel }: { hotel: Hotel }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<Review[]>([]);

  const { t } = useTranslation();

  const load = async () => {
    setIsLoading(true);
    try {
      const l = await getLocaleLanguage();

      let langValue: LANG | undefined = l as LANG | undefined;

      if (langValue === LANG.en) langValue = "en-gb" as any;

      const data = await BookingComReviews({
        hotel_id: String(hotel.hotel_id),
        languagecode: langValue,
      });
      setData(data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fireTagManagerEvent("reviews_opened", {
      name: hotel.property?.name,
      bookingcom_id: hotel.hotel_id,
      collection_id: hotel._collection?._id,
      isFree: hotel._collection?.isFree,
    });
    load();
  }, []);

  return (
    <div>
      <div className="d-flex justify-content-center p-2 border-bottom">
        <p className="fs-body-sm medium m-0">{t("ds.reviews_title")}</p>
      </div>
      <div className="modal-body p-4">
        {isLoading && (
          <>
            <Skeleton width={100} />
            <Skeleton width={150} />
            <Skeleton width={200} />
            <Skeleton height={200} />
            <br />
            <br />
            <Skeleton width={100} />
            <Skeleton width={150} />
            <Skeleton width={200} />
            <Skeleton height={200} />
          </>
        )}
        {data.map((rev, key) => {
          return (
            <div key={key}>
              <div className="d-flex justify-content-between gap-4">
                <div>
                  <p className="fs-body-xs dark-grey-color">
                    {datetimeToString(rev.date)}
                  </p>
                  <div className="height-4"></div>
                  <p className="fs-h4 medium">
                    {rev.title_translated ? rev.title_translated : rev.title}
                  </p>
                  <div className="height-4"></div>
                  <p className="fs-body">{rev.author.name}</p>
                  <div className="height-4"></div>
                  <p className="fs-body-sm dark-grey-color light">
                    {rev.stayed_room_info.room_name} -{" "}
                    {rev.stayed_room_info.num_nights} {t("ds.nights")}
                  </p>
                </div>
                <div>
                  <div
                    className="fs-h4 black-background white-color medium p-1 text-center"
                    style={{ borderRadius: "5px" }}
                  >
                    {Math.min(rev.average_score * 2.5, 10).toFixed(0)}
                  </div>
                </div>
              </div>

              <div className="height-20"></div>

              <div className="d-flex flex-column gap-2">
                <div className="d-flex gap-1">
                  <div>
                    <IconNew
                      icon="check_circle"
                      fill
                      style={{ fill: "green" }}
                    />
                  </div>
                  <p className="fs-body-sm">
                    <span className="medium">{t("ds.pros")}</span>:{" "}
                    {rev.pros_translated ? rev.pros_translated : rev.pros}
                  </p>
                </div>
                <div className="d-flex gap-1">
                  <div>
                    <IconNew icon="cancel" style={{ fill: "red" }} />
                  </div>
                  <p className="fs-body-sm">
                    <span className="medium">{t("ds.cons")}</span>:{" "}
                    {rev.cons_translated ? rev.cons_translated : rev.cons}
                  </p>
                </div>
              </div>

              <div
                className="horizontal-divider"
                style={{ margin: "25px 0" }}
              ></div>
            </div>
          );
        })}
      </div>
    </div>
  );
});

export const HotelResult = memo(
  forwardRef(
    (
      {
        hotel,
        onSelect,
        onClick,
        focussed,
        dest_label,
        searchParams,
        currentSelected,
        setCurrentSelected,
      }: {
        hotel: Hotel;
        onSelect(id: string): void;
        onClick(
          id: string,
          directPrice: number,
          bookingPrice: number,
          hotelsPrice: number,
        ): void;
        focussed: boolean;
        dest_label?: string;
        searchParams: SearchParameters;
        currentSelected: string;
        setCurrentSelected(id: string): void;
      },
      ref,
    ) => {
      const isxLargeScreen = useMediaQuery({ query: x_LARGE_SCREEN });

      const { t } = useTranslation();
      const user = useSelector(selectUser);

      const [priorityIndex, setPriorityIndex] = useState(
        hotel._collection?.priorityIndex ?? 0,
      );
      const [isLoading, setIsLoading] = useState(false);

      const patchPriorityIndex = async () => {
        setIsLoading(true);
        try {
          await CollectionController.patchCollection({
            collectionId: hotel._collection?._id,
            priorityIndex,
          });
        } catch (error) {
          console.log(error);
          alert(error);
        }
        setIsLoading(false);
      };

      let directPrice = hotel?.property?.priceBreakdown?.grossPrice?.value ?? 0;
      if (hotel?._collection?.preData?.deltaPriceBookingCom)
        directPrice =
          (hotel?.property?.priceBreakdown?.grossPrice?.value ?? 0) /
          (1 + hotel._collection.preData.deltaPriceBookingCom);

      const bookingPrice =
        hotel?.property?.priceBreakdown?.grossPrice?.value ?? 0;

      let hotelsPrice = hotel?.property?.priceBreakdown?.grossPrice?.value ?? 0;
      if (hotel?._collection?.preData?.deltaPriceHotelsCom)
        hotelsPrice =
          directPrice * (1 + hotel._collection.preData.deltaPriceHotelsCom);

      const cutPrice = Math.min(bookingPrice, hotelsPrice);

      let advantages = hotel._collection?.preData?.advantagesOnDirectWebsite;
      if (advantages) {
        advantages = advantages.slice(0, 3); // Keep only the first three elements
      }

      const locationAccess0 = hotel.accessibilityLabel;
      const locationAccess1 = hotel.accessibilityLabel
        ?.split("‎")[1]
        ?.split("‬")[0];
      const locationAccess2 = hotel.accessibilityLabel
        ?.split("‎")[2]
        ?.split("‬")[0];

      const urlGoogle = `https://google.com/search?q=${encodeURIComponent(
        `${hotel.property.name.replace(/&/g, "")} ${dest_label?.split(",")[0]}`,
      )}`;

      let urlWebsite = hotel._collection?.census?.website;
      if (urlWebsite && !urlWebsite.includes("http"))
        urlWebsite = `https://${urlWebsite}`;

      const fallbackUrl = urlWebsite ?? urlGoogle;

      const showSuggested = false;

      let showTopChoice = false;
      if (hotel._collection) {
        if (!hotel._collection.isFree) showTopChoice = true;
      }

      const showCertified = hotel._collection ? true : false;
      const isUnverifiedPrice = hotel._collection ? false : true;

      const isSmallScreen = useMediaQuery({ query: SMALL_SCREEN });

      const isSelected = currentSelected === String(hotel.hotel_id);

      return (
        <div
          onMouseEnter={() => {
            onSelect(String(hotel.hotel_id));
          }}
          onMouseLeave={() => {
            onSelect("");
          }}
          style={{
            ...(focussed || isSelected
              ? {
                  boxShadow: "0px 0px 20px 0px gray",
                  border: "1px solid black",
                }
              : {}),
          }}
          className="hotel-result d-flex flex-column flex-md-row white-background rounded"
          id={String(hotel.hotel_id)}
          data-hotel-id={String(hotel._collection?._id || hotel.property.id)}
        >
          {user?.isAdmin && hotel._collection && (
            <>
              <div
                style={{
                  position: "absolute",
                  scale: "0.8",
                  left: 0,
                  top: 0,
                  zIndex: "100",
                  maxWidth: "150px",
                  width: "100%",
                  display: "flex",
                  gap: "5px",
                }}
              >
                <Input
                  onChange={(e) => setPriorityIndex(Number(e.target.value))}
                  value={priorityIndex}
                  className="w100"
                />
                <Button
                  loading={isLoading}
                  onClick={() => {
                    patchPriorityIndex();
                  }}
                />
              </div>
            </>
          )}

          {showSuggested && (
            <div className="suggested-badge primary-background">
              <p className="bodytext medium">{t("ds.suggested")}</p>
            </div>
          )}

          <div
            className={`image-container overflow-hidden m-md-3 cursor-pointer ${
              !isSmallScreen ? "rounded" : ""
            }`}
            style={{
              flex: "0 0 auto",
              minWidth: "190px",
              minHeight: "190px",
              backgroundImage: `url(${hotel.property.photoUrls[0].replace(
                "square60",
                "square600",
              )})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
            onClick={() => {
              setCurrentSelected(String(hotel.hotel_id));
              onClick(
                String(hotel.hotel_id),
                directPrice,
                bookingPrice,
                hotelsPrice,
              );
            }}
          ></div>

          <div
            className="hotel-details w100 flex-d flex-column pb-1 pb-md-4"
            style={{ flex: "1 1 auto", minWidth: 0, overflow: "hidden" }}
          >
            <div className="d-flex h-100 flex-column justify-content-between">
              <div className="mb-auto">
                {showTopChoice && (
                  <OverlayTrigger
                    placement="top"
                    flip={true}
                    overlay={<Tooltip>{t("ds.top_choice_popover")}</Tooltip>}
                  >
                    <div
                      className="top-choice-badge black-background d-flex align-items-center mb-2 cursor-pointer"
                      style={{ width: "fit-content" }}
                    >
                      <IconNew
                        icon="top_partner"
                        size={16}
                        style={{ fill: "white" }}
                      />
                      <span className="fs-body-xs white-color text-nowrap m-1">
                        {t("ds.top_choice")}
                      </span>
                    </div>
                  </OverlayTrigger>
                )}

                <div
                  className="gap-2"
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="d-flex align-items-center"
                    style={{ minWidth: 0 }}
                  >
                    <p
                      title={hotel.property.name}
                      onClick={() => {
                        setCurrentSelected(String(hotel.hotel_id));
                        onClick(
                          String(hotel.hotel_id),
                          directPrice,
                          bookingPrice,
                          hotelsPrice,
                        );
                      }}
                      className="fs-h4 medium underline cursor-pointer text-truncate mb-0"
                      style={{ minWidth: 0 }}
                    >
                      {hotel.property.name}
                    </p>
                    <div style={{ marginLeft: "4px" }}>
                      <Stars
                        stars={hotel.property.accuratePropertyClass}
                        className="black-color"
                      />
                    </div>
                  </div>
                </div>

                <div className="height-4"></div>

                <div className="d-flex align-items-center gap-1">
                  <div className="d-flex align-items-end gap-1">
                    <div style={{ width: "20px" }}>
                      <IconNew icon="star_fill" style={{ fill: "#F6A90E" }} />
                    </div>
                    <p className="fs-h4 medium review-score">
                      {hotel.property.reviewScore}
                    </p>
                    <p className="fs-body light review-summary text-truncate">
                      {hotel.property.reviewScoreWord} (
                      <span
                        onClick={() => {
                          openModal({ content: <Reviews hotel={hotel} /> });
                        }}
                        className="cursor-pointer underline"
                      >
                        {hotel.property.reviewCount} {t("ds.reviews")}
                      </span>
                      )
                    </p>
                  </div>
                </div>

                <div>
                  <p className="dark-grey-color fs-body mt-1">
                    {locationAccess1 || locationAccess2 ? (
                      <>
                        {locationAccess1} - {locationAccess2}{" "}
                      </>
                    ) : (
                      <>{locationAccess0} </>
                    )}

                    {!isxLargeScreen && (
                      <>
                        -{" "}
                        {
                          <span
                            onClick={() => {
                              openModal({
                                content: (
                                  <div
                                    style={{
                                      height: "100vh",
                                      width: "100%",
                                    }}
                                  >
                                    <HotelsMap
                                      hotels={[hotel]}
                                      selected={String(hotel.hotel_id)}
                                      onClick={() => {}}
                                    />
                                  </div>
                                ),
                              });
                            }}
                            className="cursor-pointer underline"
                          >
                            {t("ds.show_map")}
                          </span>
                        }
                      </>
                    )}
                  </p>
                </div>
              </div>
              {hotel._collection?.publicContacts &&
                hotel._collection.publicContacts.length > 0 && (
                  <Button
                    style={{ width: "fit-content" }}
                    icon="call"
                    variant="secondary"
                    className={`py-1 px-2 ${isSmallScreen && "mt-3"}`}
                    text={t("ds.contact_hotel")}
                    textClass="fs-body-sm medium"
                    onClick={() => {
                      openModal({
                        style: {
                          maxWidth: "400px",
                        },
                        content: (
                          <div className={`p-3 ${isSmallScreen && "mt-5"}`}>
                            <HotelContacts
                              hotelContacts={
                                hotel._collection?.publicContacts || []
                              }
                            />
                          </div>
                        ),
                      });
                    }}
                  />
                )}
            </div>
          </div>

          <div
            className="price-details"
            style={{ flex: "0 0 auto", minWidth: 0 }}
          >
            <div
              className="horizontal-divider d-md-none"
              style={{ margin: "10px 0 8px 0" }}
            ></div>
            <div className="d-flex flex-row justify-content-between align-items-center justify-content-md-end">
              <div>
                <p className="fs-body medium d-block d-md-none">
                  {t("ds.direct_website")}
                  {showCertified && showTopChoice && (
                    <OverlayTrigger
                      placement="top"
                      flip={true}
                      overlay={
                        <Tooltip style={{ color: "red!important" }}>
                          {t("ds.certified_popover")}
                        </Tooltip>
                      }
                    >
                      <span>
                        <IconNew
                          className="m-1"
                          icon="certieficate"
                          size={16}
                        />
                      </span>
                    </OverlayTrigger>
                  )}
                  {isUnverifiedPrice && (
                    <OverlayTrigger
                      placement="top"
                      flip={true}
                      overlay={<Tooltip>{t("ds.estimated_price")}</Tooltip>}
                    >
                      <span
                        style={{ display: "inline-block", marginLeft: "5px" }}
                      >
                        <div className="fs-body-xs medium border px-1 d-md-none">
                          {t("ds.unverified")}
                        </div>
                      </span>
                    </OverlayTrigger>
                  )}
                </p>
              </div>

              <div className="d-flex flex-row gap-2 items-center">
                <div className="d-flex gap-1 d-block d-md-none align-items-end">
                  {cutPrice > directPrice && (
                    <p className="cut-price">€{cutPrice.toFixed(0)}</p>
                  )}
                  <p className="fs-h2 medium">€{directPrice.toFixed(0)}*</p>
                </div>
              </div>
            </div>

            <div className="height-12 d-none d-md-block"></div>

            <div>
              <div className="d-flex flex-row flex-md-column d-none d-md-block">
                <p className="fs-body medium">
                  {t("ds.direct_website")}
                  {showCertified && showTopChoice && (
                    <OverlayTrigger
                      placement="top"
                      flip={true}
                      overlay={
                        <Tooltip style={{ color: "red!important" }}>
                          {t("ds.certified_popover")}
                        </Tooltip>
                      }
                    >
                      <span>
                        <IconNew
                          className="m-1"
                          icon="certieficate"
                          size={20}
                        />
                      </span>
                    </OverlayTrigger>
                  )}
                </p>
                <div className="d-flex gap-1 align-items-end">
                  <p className="fs-h2 medium">€{directPrice.toFixed(0)}*</p>
                  {isUnverifiedPrice && (
                    <OverlayTrigger
                      placement="top"
                      flip={true}
                      overlay={<Tooltip>{t("ds.estimated_price")}</Tooltip>}
                    >
                      <span>
                        <div className="fs-body-xs medium border px-1 d-none d-md-block">
                          {t("ds.unverified")}
                        </div>
                      </span>
                    </OverlayTrigger>
                  )}
                  {cutPrice > directPrice && (
                    <p className="fs-body medium cut-price">
                      €{cutPrice.toFixed(0)}
                    </p>
                  )}
                </div>
              </div>

              {advantages && advantages.length > 0 && (
                <div className="advantages-section rounded mt-1">
                  <p className="fs-body-xs medium">{t("ds.why_direct")}</p>
                  <div className="height-2"></div>
                  {cutPrice > directPrice && (
                    <div className="d-flex align-items-start gap-1">
                      <div
                        className="position-relative"
                        style={{ width: "16px", height: "16px" }}
                      >
                        <div
                          className="position-absolute"
                          style={{ top: "-3px" }}
                        >
                          <IconNew icon="booking" size={16} />
                        </div>
                      </div>
                      <p className="fs-body-xs text-red medium">
                        {`- ${(cutPrice - directPrice).toFixed(0)}€ ${t(
                          "ds.estimated",
                        )}`}
                      </p>
                    </div>
                  )}
                  {advantages.map((a, key) => (
                    <div className="d-flex align-items-center gap-1" key={key}>
                      <IconNew
                        icon="check_circle"
                        fill
                        size={16}
                        style={{ fill: "green" }}
                      />
                      <p className="fs-body-xs light">
                        {t("ds." + a.replaceAll(" ", ""))}
                      </p>
                    </div>
                  ))}
                </div>
              )}

              <div className="height-8"></div>

              <Button
                textClass="fs-body-sm medium"
                onClick={() => {
                  CollectionController.clickOnCollection(
                    hotel._collection?._id ??
                      `${hotel.property.name}-${hotel.property.id}`,
                    {
                      btn: "visit_website",
                      directPrice,
                      cutPrice,
                      bookingPrice,
                      hotelsPrice,
                      bookingComId: hotel.property.id,
                      checkin: hotel.property.checkinDate,
                      checkout: hotel.property.checkoutDate,
                    },
                  );
                  fireTagManagerEvent("book_on_site", {
                    name: hotel.property?.name,
                    bookingcom_id: hotel.hotel_id,
                    collection_id: hotel._collection?._id,
                    isFree: hotel._collection?.isFree,
                    amount: directPrice.toFixed(0),
                    destination: dest_label,
                    dates:
                      hotel.property.checkinDate +
                      " - " +
                      hotel.property.checkoutDate,
                  });

                  const newTabUrl =
                    "/directsearch/redirect?" +
                    new URLSearchParams({
                      ...searchParams,
                      collectionId: hotel._collection?._id.toString() || "",
                      fallbackUrl,
                    });

                  window.open(newTabUrl, "_blank");
                }}
                className="w100"
                text={
                  showCertified ? t("ds.visit_webiste") : t("ds.look_website")
                }
              />
            </div>
          </div>
        </div>
      );
    },
  ),
);

export default function Results() {
  const isSmallScreen = useMediaQuery({ query: SMALL_SCREEN });
  const isShortScreen = useMediaQuery({ query: LARGE_SHORT_SCREEN });
  const isxLargeScreen = useMediaQuery({ query: x_LARGE_SCREEN });
  const [isMobileMinified, setIsMobileMinified] = useState(true);
  const [impressions, setImpressions] = useState<string[]>([]);
  const [impressionsSpot, setImpressionsSpot] = useState<string[]>([]);
  const { t } = useTranslation();

  const [urlParams] = useSearchParams();

  const [modalDetailHotelID, setModalDetailHotelID] = useState<string>("");

  const searchParams = useMemo<SearchParameters>(() => {
    const paramsObject: SearchParameters = Object.fromEntries(
      urlParams.entries(),
    );

    const childrens = paramsObject.children_age?.split(",") || [];
    fireTagManagerEvent("search_started", {
      destination: paramsObject.dest_label,
      dates: paramsObject.departure_date + " - " + paramsObject.arrival_date,
      adults: paramsObject.adults ?? 2,
      children: childrens.length,
    });

    window.scrollTo(0, 0);
    return paramsObject;
  }, [urlParams]);

  const [focussed, setFocussed] = useState("");
  const [currentSelected, setCurrentSelected] = useState("");
  const [isScrolled, setIsScrolled] = useState<"top" | "scroll" | "bottom">(
    "top",
  );
  const [searchbarHeight, setSearchbarHeight] = useState(185);
  const searchContainerRef = useRef<HTMLDivElement>(null);
  const [navH, setNavH] = useState(0);

  const sortOptions = [
    { name: t("ds.sort_recommended"), value: "" },
    { name: t("ds.sort_price"), value: "price" },
    { name: t("ds.sort_distance"), value: "distance" },
    { name: t("ds.sort_review_score"), value: "bayesian_review_score" },
  ];

  const [selectedSort, setSelectedSort] = useState("");

  const navigate = useNavigate();

  const handleSortChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedSort(e.target.value);
    const newParams = new URLSearchParams(urlParams);
    if (e.target.value) {
      newParams.set("sort_by", e.target.value);
    } else {
      newParams.delete("sort_by");
    }
    navigate(`?${newParams.toString()}`);
  };

  useEffect(() => {
    if (!searchParams.sort_by) {
      setSelectedSort("");
    }

    setModalDetailHotelID("");
    setCurrentSelected("");
    const timer = setTimeout(() => {
      const searchbarElement = document.getElementById("searchbar");
      if (searchbarElement) {
        setSearchbarHeight(searchbarElement.offsetHeight + 85);
      }

      clearTimeout(timer);
    }, 400);

    const footer = document.querySelector("footer");
    const nav = document.querySelector("nav");
    const navH = nav?.clientHeight ?? 0;
    setNavH(navH);

    const handleScroll = () => {
      try {
        if (
          searchContainerRef.current!.getBoundingClientRect().bottom - navH <
            0 &&
          footer!.getBoundingClientRect().top - window.innerHeight > 0
        )
          setIsScrolled("scroll");
        else {
          if (footer!.getBoundingClientRect().top - window.innerHeight < 0)
            setIsScrolled("bottom");

          if (
            searchContainerRef.current!.getBoundingClientRect().bottom - navH >
            0
          )
            setIsScrolled("top");
        }
      } catch (error) {}
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [searchParams]);

  useEffect(() => {
    if (focussed) {
      const t = setTimeout(() => {
        setFocussed("");
      }, 5000);

      return () => clearTimeout(t);
    }
  }, [focussed]);

  useEffect(() => {
    if (isSmallScreen && !isMobileMinified) {
      document.querySelector("html")!.style.overflow = "hidden";
    } else {
      document.querySelector("html")!.style.overflowY = "scroll";
    }
    return () => {
      document.querySelector("html")!.style.overflowY = "scroll";
    };
  }, [isMobileMinified]);

  const [isLoading, setIsLoading] = useState(true);
  const [selected, setSelected] = useState("");
  const [hotels, setHotels] = useState<Hotel[]>([]);
  const [directPrice, setDirectPrice] = useState(0);
  const [bookingPrice, setBookingPrice] = useState(0);
  const [hotelsPrice, setHotelsPrice] = useState(0);

  const getCityFromCoordinates = async (
    lat: number,
    lng: number,
  ): Promise<string> => {
    return new Promise<string>((resolve) => {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ location: { lat, lng } }, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK && results) {
          for (let result of results) {
            if (result.types.includes("locality")) {
              resolve(result.address_components[0].long_name);
              return;
            }
          }
        }
        resolve("Unknown");
      });
    });
  };

  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const { openLoad, load } = useRandomLoad();

  const search = useCallback(async () => {
    if (!searchParams) return;

    setIsLoading(true);
    try {
      await Promise.all([
        (async () => {
          const data = await BookingComSearch(searchParams);
          setHotels(data);
        })(),
        (async () => {
          if (isFirstLoad) await sleep(3000);
        })(),
      ]);
    } catch (error) {
      console.log(error);
    }
    setIsFirstLoad(false);
    setIsLoading(false);
  }, [searchParams]);

  useEffect(() => {
    if (!searchParams) return;

    if (
      searchParams.dest_id ||
      (searchParams.latitude && searchParams.longitude)
    )
      search();

    openLoad();
  }, [searchParams, search]);

  useEffect(() => {
    if (hotels.length > 0) {
      setTimeout(() => checkVisibleHotels(), 100);
    }
  }, [hotels]);

  const scrollTimeout = useRef<NodeJS.Timeout | null>(null);
  const SendImpressionTimeout = useRef<NodeJS.Timeout | null>(null);

  const checkVisibleHotels = useCallback(() => {
    // console.log("Checking visible hotels");
    const hotelElements = document.querySelectorAll(".hotel-result");
    const windowHeight = window.innerHeight;
    const viewportCenter = windowHeight * 0.6; // 60% of viewport height
    const viewportRange = viewportCenter * 0.5; // Range around center point

    hotelElements.forEach((element) => {
      const rect = element.getBoundingClientRect();
      const elementCenter = rect.top + rect.height / 2;
      const hotelId = element.getAttribute("data-hotel-id");

      if (!hotelId) {
        console.warn("Missing data-hotel-id attribute for element:", element);
        return;
      }

      // Check if element is in the center 35% of viewport
      if (Math.abs(elementCenter - viewportCenter) <= viewportRange) {
        if (!impressionsSpot.includes(hotelId)) {
          // console.log("Adding hotelId to impressionsSpot:", hotelId);
          setImpressionsSpot((prev) => {
            const newSpot = [...prev, hotelId];
            // console.log("New impressionsSpot state:", newSpot);
            return newSpot;
          });
        }
      } else {
        if (impressionsSpot.includes(hotelId)) {
          setImpressionsSpot((prev) => prev.filter((id) => id !== hotelId));
        }
      }
    });

    setImpressions(impressionsSpot);
    // console.log(impressions);
  }, [impressionsSpot]);

  const handleScroll = useCallback(() => {
    if (scrollTimeout.current) {
      clearTimeout(scrollTimeout.current);
    }

    // Wait for scroll to end (500ms of no scroll events)
    const scrollEndTimeout = setTimeout(() => {
      // After scroll ends, set up both timeouts with their original delays
      scrollTimeout.current = setTimeout(() => {
        checkVisibleHotels();
      }, 3000);

      if (SendImpressionTimeout.current === null) {
        // console.log("starting impression timeout...");
        SendImpressionTimeout.current = setTimeout(() => {
          // console.log("sending impressions...");
          if (impressions.length > 0) {
            ImpressionsController.sendImpressions(impressions);
            setImpressions([]);
          }
          SendImpressionTimeout.current = null;
        }, 10000);
      }
    }, 500);

    // Store the scroll end timeout so we can clear it on next scroll
    scrollTimeout.current = scrollEndTimeout;
  }, [checkVisibleHotels, impressions]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      if (scrollTimeout.current) {
        clearTimeout(scrollTimeout.current);
      }
    };
  }, [handleScroll]);

  return (
    <div
      style={{
        width: "100vw",
        minHeight: "100vh",
        background: "#FAFAFA",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {isLoading && isFirstLoad && (
        <div
          style={{
            zIndex: 999,
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundPosition: "center center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "rgb(255,255,255, 1)",
          }}
        >
          <div
            style={{
              backgroundImage: `url(${load?.img})`,
              backgroundSize: "cover",
              backgroundPosition: "center center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: isSmallScreen ? "15px 10px" : "150px 100px",
              borderRadius: "20px",
              height: isSmallScreen ? "100%" : "unset",
            }}
          >
            <div
              style={{
                width: "100%",
                maxWidth: "500px",
                borderRadius: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                padding: "20px",
                gap: "20px",
                background: "white",
              }}
            >
              <div style={{ transform: "translateX(-10px)" }}>
                <Loader scale={0.4} small />
              </div>

              <p className="text-center fs-body">{t("ds." + load?.text)}</p>
            </div>
          </div>
        </div>
      )}

      <div
        ref={searchContainerRef}
        style={{
          position: isSmallScreen ? "fixed" : "relative",
          zIndex: 10,
          width: "100%",
          padding: isShortScreen
            ? "55px 18px 10px 18px"
            : "75px 18px 10px 18px",
          maxWidth: "1200px",
          backgroundColor: "#FAFAFA",
        }}
      >
        {isSmallScreen && !isMobileMinified && (
          <div
            style={{
              position: "fixed",
              zIndex: 10,
              top: 0,
              left: 0,
              width: "100%",
              height: "100vh",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            }}
            onClick={(e) => {
              e.stopPropagation();
              setIsMobileMinified(true);
            }}
          ></div>
        )}
        <Search
          mode={isSmallScreen ? "mobile" : "desktop"}
          searchParameters={searchParams}
          showFilters={true}
          isMobileMinified={isMobileMinified}
          setIsMobileMinified={setIsMobileMinified}
        />
      </div>

      <div className="d-flex flex-row">
        <div
          style={{
            marginTop: isSmallScreen ? searchbarHeight : 0,
            position: "relative",
            padding: "0 18px 0px 18px",
          }}
          className="w100"
          // onClick={() => {setIsMobileMinified(true);}}
        >
          {!isLoading &&
            hotels &&
            searchParams.search_type === "hotel" &&
            !hotels.find(
              (h) => String(h.hotel_id) === String(searchParams.dest_id),
            ) && (
              <div
                style={{
                  maxWidth: "max-content",
                  fontSize: "14px",
                  borderRadius: "8px",
                }}
                className="py-1 px-3 mb-3 danger-background white-color"
              >
                {t("ds.hotel_not_available")}: {searchParams.dest_label}
              </div>
            )}

          {!isLoading && hotels && hotels.length > 0 && (
            <>
              {/* Row Results */}
              <div className="d-flex align-items-center justify-content-between pb-2">
                <p className="fs-body-xs regular dark-grey-color">
                  {hotels.length} {t("ds.results")}
                </p>
                {/* Sort By */}
                <div className="d-flex align-items-center gap-2">
                  <p className="fs-body-xs regular dark-grey-color text-nowrap">
                    {t("ds.sort_by")}
                  </p>
                  <Form.Select
                    size="sm"
                    value={selectedSort}
                    onChange={handleSortChange}
                  >
                    {sortOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.name}
                      </option>
                    ))}
                  </Form.Select>
                </div>
              </div>
              <div className="d-flex gap-3 pb-3">
                <div
                  className="results-list d-flex flex-column gap-3"
                  style={{
                    width: !isxLargeScreen ? "100%" : "65vw",
                    maxWidth: !isxLargeScreen ? "100%" : "65vw",
                  }}
                >
                  {hotels.map((h, index) => (
                    <HotelResult
                      focussed={focussed === String(h.hotel_id)}
                      onSelect={(id) => {
                        setSelected(id);
                      }}
                      onClick={(id, directPrice, bookingPrice, hotelsPrice) => {
                        setModalDetailHotelID(id);
                        setDirectPrice(directPrice);
                        setBookingPrice(bookingPrice);
                        setHotelsPrice(hotelsPrice);
                      }}
                      key={`hotel_${h.hotel_id}_${index}`}
                      hotel={h}
                      dest_label={searchParams.dest_label}
                      searchParams={searchParams}
                      currentSelected={currentSelected}
                      setCurrentSelected={setCurrentSelected}
                    />
                  ))}
                </div>
              </div>
            </>
          )}

          {isLoading && !isFirstLoad && (
            <div className="d-flex gap-3">
              <ResultSkeletonList isxLargeScreen={isxLargeScreen} />
            </div>
          )}

          {hotels && hotels.length === 0 && !isLoading && (
            <div
              className="w100 d-flex flex-column align-items-center justify-content-center"
              style={{ height: "80vh" }}
            >
              <img
                src={no_results}
                style={{ width: isSmallScreen ? "120px" : "150px" }}
                alt="No results"
              />
              <p className="h3 regular pb-2 mt-5">{t("ds.no_results")}</p>
            </div>
          )}
        </div>

        {isxLargeScreen && (
          <div
            className="border shadow"
            style={{
              position: isScrolled === "scroll" ? "fixed" : "relative",
              top: 0,
              marginTop: isScrolled === "scroll" ? `${navH + 25}px` : "25px",
              bottom: 0,
              marginBottom: isScrolled === "scroll" ? "25px" : "20px",
              right: isScrolled === "scroll" ? "8px" : 0,
              transform: isScrolled === "scroll" ? "" : "translateX(-16px)",
              borderRadius: "20px",
              overflow: "hidden",
              height: `calc(100vh - ${navH + 50}px)`,
              width: "32vw",
              maxWidth: "32vw",
              minWidth: "32vw",
              flex: "0 0 32vw",
              alignSelf: isScrolled === "top" ? "flex-start" : "flex-end",
            }}
          >
            {modalDetailHotelID !== "" && (
              <ModalDetailHotel
                hotelId={modalDetailHotelID}
                onClose={() => setModalDetailHotelID("")}
                directPrice={directPrice}
                bookingPrice={bookingPrice}
                hotelsPrice={hotelsPrice}
                isScroll={isScrolled === "scroll"}
                dest_label={searchParams.dest_label || ""}
                searchParams={searchParams}
                setCurrentSelected={setCurrentSelected}
              />
            )}
            {!!hotels.length && (
              <HotelsMap
                isLoading={isLoading}
                onClick={async (hotelId) => {
                  setFocussed(hotelId);
                  setSelected(hotelId);
                  const element = document.getElementById(hotelId);
                  const hotel = hotels.find(
                    (hotel) => hotel.hotel_id === Number(hotelId),
                  );
                  if (hotel) {
                    const city = await getCityFromCoordinates(
                      hotel.property.latitude,
                      hotel.property.longitude,
                    );
                    fireTagManagerEvent("map_click", {
                      destination: city,
                      dates:
                        hotel.property.checkinDate +
                        " - " +
                        hotel.property.checkoutDate,
                      name: hotel.property?.name,
                    });
                  }
                  if (element) {
                    element.scrollIntoView({
                      behavior: "smooth",
                      block: "center",
                      inline: "center",
                    });
                  }
                }}
                searchLocation={(coords, radius) => {
                  const objCopy: SearchParameters = {
                    ...searchParams,
                    latitude: String(coords.lat),
                    longitude: String(coords.lng),
                    radius: String(radius),
                    search_type: undefined,
                    dest_id: undefined,
                  };

                  newSearch(objCopy, navigate);
                }}
                selected={selected}
                hotels={hotels}
              />
            )}
          </div>
        )}

        {!isxLargeScreen && modalDetailHotelID !== "" && (
          <ModalDetailHotel
            hotelId={modalDetailHotelID}
            onClose={() => setModalDetailHotelID("")}
            directPrice={directPrice}
            bookingPrice={bookingPrice}
            hotelsPrice={hotelsPrice}
            isScroll={isScrolled === "scroll"}
            searchParams={searchParams}
            dest_label={searchParams.dest_label || ""}
            setCurrentSelected={setCurrentSelected}
          />
        )}
      </div>
    </div>
  );
}
